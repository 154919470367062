#cookie_banner-wrapper {
  z-index: 9001;
  position: relative;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

#cookie_banner-wrapper .cookie_container {
  position: fixed;
  bottom: 0;
  overflow: hidden;
  padding: 15px;
  background: #222;
  color: #fff;
  box-sizing: border-box;
  font-size: 0.8em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@media (max-width: 499px) {
  #cookie_banner-wrapper .cookie_container {
    left: 0;
    right: 0;
  }
}

@media (min-width: 500px) {
  #cookie_banner-wrapper .cookie_container {
    width: 300px;
    bottom: 20px;
    right: 20px;
  }
}

@media screen and (min-width: 768px) {
  #cookie_banner-wrapper .cookie_container {
    font-size: 0.9em;
  }
}

#cookie_banner-wrapper .cookie_container .cookie_message a {
  text-decoration: none;
  color: #31a8f0;
}

#cookie_banner-wrapper .cookie_container .cookie_message a:hover {
  text-decoration: underline;
  color: #ccfaff;
}

#cookie_banner-wrapper .cookie_container .cookie_message a:active {
  color: #b2f7ff;
}

#cookie_banner-wrapper .cookie_container .cookie_btn {
  background-color: #f1d600;
  color: #222;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 6px 12px;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

#cookie_banner-wrapper .cookie_container .cookie_btn:hover {
  background-color: #ffe40c;
}

@media print {
  #cookie_banner-wrapper {
    display: none;
  }
}

.mp-item-card {
  display: flex;
  flex-direction: column;
}

.mp-item-card__footer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mp-image-placeholder {
  position: relative;
}

.mp-image-placeholder img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.mp-image-placeholder img.mp-object-fit-cover {
  height: 100%;
  object-fit: cover;
}

.mp-image-placeholder img.mp-object-fit-contain {
  height: 100%;
  object-fit: contain;
}

.mp-image-placeholder--aspect-ratio-1-1 {
  padding-bottom: 100%;
}

.mp-image-placeholder--aspect-ratio-4-3 {
  padding-bottom: 75%;
}

.mp-image-placeholder--aspect-ratio-16-9 {
  padding-bottom: 56.25%;
}

.mp-text-line-height-1-3 {
  line-height: 1.3;
}

.mp-age-verification-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999;
}

.mp-age-verification-modal__modal {
  background-color: white;
  min-width: 10%;
  max-width: 30rem;
  min-height: 10rem;
  max-height: 85vh;
  overflow: auto;
}

@media (hover: none) {
  .mp-age-verification-modal__modal {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

html.age-verification-modal-is-open {
  max-height: 100vh;
  overflow: hidden;
}

.mp-cookie-concent-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999999;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}

.mp-cookie-concent-modal .uk-button {
  border-radius: 0;
}
