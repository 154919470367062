
// Plugins
@import "plugins/cookie.scss";


.mp-item-card {
  display: flex;
  flex-direction: column;
}

.mp-item-card__footer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mp-image-placeholder {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    &.mp-object-fit-cover {
      height: 100%;
      object-fit: cover;
    }

    &.mp-object-fit-contain {
      height: 100%;
      object-fit: contain;
    }
  }
}

.mp-image-placeholder--aspect-ratio-1-1 {
  padding-bottom: 100%;
}

.mp-image-placeholder--aspect-ratio-4-3 {
  padding-bottom: 75%;
}

.mp-image-placeholder--aspect-ratio-16-9 {
  padding-bottom: 56.25%;
}

.mp-text-line-height-1-3 {
  line-height: 1.3;
}



.mp-age-verification-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999;
}

.mp-age-verification-modal__modal {
  background-color: white;
  min-width: 10%;
  max-width: 30rem;
  min-height: 10rem;
  max-height: 85vh;
  overflow: auto;

  @media (hover: none) {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

html.age-verification-modal-is-open {
  max-height: 100vh;
  overflow: hidden;
}


.mp-cookie-concent-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999999;
  box-shadow: 0 -2px 8px rgba(0,0,0,0.15);

  .uk-button {
    border-radius: 0;
  }
}
